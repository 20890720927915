const colors = require('@/utils/colors')
const resolutions = require('@/utils/resolutions')

export default {
  name: 'Sidebar',

  props: {
    color: {
      type: String,
      default: '#FFE800'
    },

    modules: {
      type: Array,
      default: []
    }
  },

  data() {
    return {
      isOpen: false,
      isMobileVersion: false,

      moduleIndex: null,
      submoduleIndex: null,

      submodulesIndex: null,
      submoduleHeight: 36,

      area: {
        container: 0,
        header: 1
      },

      style: {
        hover: 0,
        active: 1
      }
    }
  },

  methods: {
    isUndefined(value) {
      return (typeof value === 'undefined')
        ? true
        : false;
    },

    putIconsColor() {
      this.$refs.icon.forEach((icon, index) => {
        icon.style.color = this.color;
      });
    },

    resizeResolution(e) {
      this.getResolution();
    },

    getResolution() {
      if (window.screen.width <= resolutions.sm) {
        this.isMobileVersion = true;
      } else if (this.isMobileVersion) {
        this.showSidebar();

        this.isMobileVersion = false;
      }
    },

    getCurrentPath() {
      let currentPath = this.$router.history.current.path;

      this.moduleIndex = null;
      this.submoduleIndex = null;

      this.modules.forEach((module, index) => {
        if (module.path === currentPath) {
          this.setModuleStyles(index, this.style.active);
          this.setHeaderStyles(index, this.style.active);

          this.moduleIndex = index;
        } else {
          this.setModuleStyles(index);
          this.setHeaderStyles(index);
        }

        if (!this.isUndefined(module.items)) {
          module.items.forEach((submodule, itemIndex) => {
            if (submodule.path === currentPath) {
              this.setSubmoduleStyles(index, itemIndex, this.style.active);

              this.submoduleIndex = index + '-' + itemIndex;
            } else {
              this.setSubmoduleStyles(index, itemIndex);
            }
          });
        }
      });
    },

    switchSidebar() {
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        this.showSidebar();
      } else {
        this.hideSidebar();
      }
    },

    showSidebar() {
      TweenMax.to(this.$refs.container, 1, {
        ease: Expo.easeOut,
        autoAlpha: 1,
        transform: 'translateX(0)'
      });
    },

    hideSidebar() {
      TweenMax.to(this.$refs.container, 1, {
        ease: Expo.easeIn,
        autoAlpha: 0.3,
        transform: 'translateX(-100%)'
      });
    },

    switchSubmodules(index, length) {
      this.submodulesIndex = (this.submodulesIndex !== index)
        ? index
        : null;

      let id = 'submodules-' + this.submodulesIndex;

      this.$refs.submodules.forEach(submodules => {
        if (submodules.id === id) {
          TweenMax.to(submodules, .5, {
            height: length * this.submoduleHeight + 'px'
          });
        } else {
          TweenMax.to(submodules, .5, {
            height: '0px'
          });
        }
      });
    },

    setModuleStyles(index, style = null) {
      if (this.moduleIndex !== index) {
        let self = document.getElementById('module-' + index);

        switch (style) {
          case this.style.hover:
            self.style.border = '1px solid ' + this.color;
            self.style.backgroundColor = colors.bgGray;
            break;
          case this.style.active:
            self.style.border = '1px solid ' + this.color;
            self.style.backgroundColor = colors.hexToRgba(this.color, 0.1);
            break;
          default:
            self.style.border = '1px solid ' + colors.transparent;
            self.style.backgroundColor = colors.transparent;
        }
      }
    },

    setHeaderStyles(index, style = null) {
      if (this.moduleIndex !== index) {
        let icon = document.getElementById('icon-' + index);

        switch (style) {
          case this.style.hover:
            icon.style.color = colors.secondary10;
            break;
          case this.style.active:
            icon.style.color = colors.secondary10;
            break;
          default:
            icon.style.color = this.color;
        }
      }
    },

    setArrowStyles(index, style = null) {
      let self = document.getElementById('arrow-' + index);

      switch (style) {
        case this.style.hover:
          self.style.color = this.color;
          break;
        default:
          self.style.color = colors.secondary10;
      }
    },

    setSubmoduleStyles(index, itemIndex, style = null) {
      let submoduleIndex = index + '-' + itemIndex;

      if (this.submoduleIndex !== submoduleIndex) {
        let self = document.getElementById(
          'submodule-' + index + '_' + itemIndex
        );

        switch (style) {
          case this.style.hover:
            self.style.color = this.color;
            break;
          case this.style.active:
            self.style.color = this.color;
            break;
          default:
            self.style.color = colors.secondary40;
        }
      }
    },

    moduleMouseOver(index, isActionable) {
      if (isActionable) {
        this.setModuleStyles(index, this.style.hover);
        this.setHeaderStyles(index, this.style.hover);
      }
    },

    moduleMouseLeave(index, isActionable) {
      if (isActionable) {
        this.setModuleStyles(index);
        this.setHeaderStyles(index);
      }
    },

    arrowMouseOver(index) {
      this.setModuleStyles(index, this.style.hover);
      this.setArrowStyles(index, this.style.hover);
    },

    arrowMouseLeave(index) {
      this.setModuleStyles(index);
      this.setArrowStyles(index);
    },

    submoduleMouseOver(index, itemIndex) {
      this.setSubmoduleStyles(index, itemIndex, this.style.hover);
    },

    submoduleMouseLeave(index, itemIndex) {
      this.setSubmoduleStyles(index, itemIndex);
    },

    goModulePath(path, items, area) {
      let isRedirectable = false;

      if (typeof path !== 'undefined') {
        let hasItems = (typeof items !== 'undefined')
          ? true
          : false;

        if (hasItems && area === this.area.header) {
          isRedirectable = true;
        }

        if (!hasItems && area === this.area.container) {
          isRedirectable = true;
        }
      }

      if (isRedirectable) {
        this.goToPath(path);
      }
    },

    goToPath(path) {
      let currentPath = this.$router.history.current.path;

      if (path !== currentPath) {
        this.$router.push(path);

        if (this.isMobileVersion) {
          this.hideSidebar();
        }
      }
    }
  },

  mounted() {
    this.putIconsColor();
    this.getResolution();
    this.getCurrentPath();
  },

  created() {
    window.addEventListener('resize', this.resizeResolution);
  },

  destroyed() {
    window.removeEventListener('resize', this.resizeResolution);
  },

  watch: {
    $route() {
      this.getCurrentPath();
    }
  }
}
