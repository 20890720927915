import Vue from 'vue';
import App from './views/app';
import router from './router';

import VCalendar from 'v-calendar';
import VueSession from 'vue-session';
import ToggleButton from 'vue-js-toggle-button';
import VueGlobalVariable from 'vue-global-var';

import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import './assets/static';

library.add(fas);
library.add(fab);
library.add(far);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(VueSession);
Vue.use(ToggleButton);
Vue.use(require('vue-moment'));

Vue.use(VCalendar, {
  firstDayOfWeek: 1
});

Vue.use(VueGlobalVariable, {
  globals: {
    $filters: {
      subsidiary_id: null,
      start_date: null,
      end_date: null
    }
  }
});

Vue.config.productionTip = true;

new Vue({
  router,
  render: function (h) {
    return h(App)
  }
}).$mount('#app');
