export default {
  props: {
    options: {
      type: Array,
      default: []
    }
  },

  data() {
    return {
      isMenu: false
    }
  },

  methods: {
    switchMenu() {
      this.isMenu = !this.isMenu;
    }
  },

  mounted() {
    let self = this;

    window.addEventListener('click', function(e) {
      if (!self.$refs.avatar.contains(e.target)) {
        self.isMenu = false;
      }
    });
  }
}
