import Sidebar from '@/components/Sidebar'
import Navbar from '@/components/Navbar'

import Logo from '@/components/_icons/Logo'

export default {
  name: 'app',

  components: {
    Sidebar,
    Navbar,
    Logo
  },

  data() {
    return {
      color: '#FFE800',
      modules: [
        {
          icon: 'folder-plus',
          name: 'Aggregator',
          path: '/aggregator'
        },
        {
          icon: 'paste',
          name: 'DataSheet',
          path: '/datasheet'
        },
        {
          icon: 'calendar-week',
          name: 'DateRange',
          path: '/daterange'
        },
        {
          icon: 'upload',
          name: 'Fileloader',
          path: '/fileloader'
        },
        {
          icon: 'filter',
          name: 'Filters',
          path: '/filters'
        },
        {
          icon: 'th-list',
          name: 'List',
          path: '/list'
        },
        {
          icon: 'compass',
          name: 'Navbar',
          path: '/navbar'
        },
        {
          icon: 'sticky-note',
          name: 'Note',
          path: '/note'
        },
        {
          icon: 'sad-tear',
          name: 'Ops',
          path: '/ops'
        },
        {
          icon: 'bell',
          name: 'Outstanding',
          path: '/outstanding'
        },
        {
          icon: 'columns',
          name: 'PanelGroup',
          path: '/panelgroup'
        },
        {
          icon: 'id-card-alt',
          name: 'ProfileInfo',
          path: '/profileinfo'
        },
        {
          icon: 'undo-alt',
          name: 'Return',
          path: '/return'
        },
        {
          icon: 'search',
          name: 'Searcher',
          path: '/searcher'
        },
        {
          icon: 'bars',
          name: 'Sidebar',
          path: '/sidebar'
        },
        {
          icon: 'traffic-light',
          name: 'Status',
          path: '/status'
        },
        {
          icon: 'thumbs-up',
          name: 'Success',
          path: '/success'
        },
        {
          icon: 'table',
          name: 'Table',
          path: '/table'
        },
        {
          icon: 'tags',
          name: 'Tabs',
          path: '/tabs'
        },
        {
          icon: 'pager',
          name: 'Target',
          path: '/target'
        },
        {
          icon: 'keyboard',
          name: 'Formulario',
          path: '/form',
          items: [
            {
              name: 'FormButton',
              path: '/form/formbutton',
            },
            {
              name: 'FormButtonSelect',
              path: '/form/formbuttonselect',
            },
            {
              name: 'FormCheckbox',
              path: '/form/formcheckbox',
            },
            {
              name: 'FormInputBox',
              path: '/form/forminputbox',
            },
            {
              name: 'FormInputLine',
              path: '/form/forminputline',
            },
            {
              name: 'FormRadiobutton',
              path: '/form/formradiobutton',
            },
            {
              name: 'FormSelect',
              path: '/form/formselect',
            },
            {
              name: 'FormTextarea',
              path: '/form/formtextarea',
            }
          ]
        },
        {
          icon: 'chart-bar',
          name: 'Gráficas',
          items: [
            {
              name: 'BarChart',
              path: '/barchart',
            },
            {
              name: 'LineChart',
              path: '/linechart',
            },
            {
              name: 'MixChart',
              path: '/mixchart',
            },
            {
              name: 'PieChart',
              path: '/piechart',
            },
            {
              name: 'RaddarChart',
              path: '/raddarchart',
            }
          ]
        },
        {
          icon: 'signature',
          name: 'MadeAt',
          path: '/madeat'
        }
      ],

      activeModule: null
    }
  },

  methods: {

  },

  mounted() {

  }
}
